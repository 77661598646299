import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
    Typography,
    Menu,
    Autocomplete
} from '@mui/material';
import { Box } from '@mui/system';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import PdfPorPacientePorExames from 'views/Laboratorio/Relatorios/PdfPacientePorExames';

const ActionButtonGeral = () => {
    // ---- INSTÂNCIAS ----
    const api = useApi();
    // ---- STATES ----
    const [allProducts, setAllProducts] = useState([]);
    const [filtredProducts, setFiltredProducts] = useState([]);
    const [product, setProduct] = useState(null);
    const [examesAll, setExamesAll] = useState(null);
    const [examesVelue, setExamesVelue] = useState(null);
    const [dataDate, setDataDate] = useState({ firstDate: '', lastDate: '' });
    const [anchorEl, setAnchorEl] = useState(null);
    const [checked, setChecked] = useState(true);
    const [switchCheck, setSwitchCheck] = useState(false);
    const [switchCheckAll, setSwitchCheckAll] = useState(false);

    const [open, setOpen] = useState(false);
    const [dataPdf, setDataPdf] = useState([]);
    const [openPdf, setOpenPdf] = useState(false);
    const [itemSelecionado, setItemSelecionado] = useState({});
    // ---- FUNÇÕES ----

    const getProducts = async () => {
        try {
            const response = await api.getAllItems();
            setAllProducts(response.data);
            setFiltredProducts(response.data.filter((item) => item.psicotropico === true));
        } catch (error) {
            toast.error(`Erro ao buscar dados: ${error.message}`);
        }
    };

    const handleChangeCheckbox = (e) => {
        const newCheckedValue = e.target.checked;
        setChecked(newCheckedValue);

        if (!newCheckedValue) {
            setFiltredProducts(allProducts.filter((item) => item.psicotropico === false));
        } else {
            setFiltredProducts(allProducts.filter((item) => item.psicotropico === true));
        }
    };

    const handleChangeSwitch = (e) => {
        const newSwitchCheckValue = e.target.checked;
        setSwitchCheck(e.target.checked);

        if (!newSwitchCheckValue) {
            setFiltredProducts(allProducts.filter((item) => item.psicotropico === true));
        } else {
            setFiltredProducts(allProducts);
        }
    };

    const handleChangeInput = (value) => {
        setExamesVelue(value);
    };
    const handleChangeInputDate = (campo, value) => {
        setDataDate({ ...dataDate, [campo]: value });
    };

    const handleItemSelect = (selectedItemId) => {
        if (selectedItemId !== null) {
            const selectedItem = examesAll?.find((item) => item.id === selectedItemId.id);

            setItemSelecionado({
                ...itemSelecionado,
                item_id: selectedItem.id
            });
        }
    };
    console.log(itemSelecionado);

    const handleNavigate = (params) => {
        if (!switchCheck && params === null && checked) {
            window.open(`/relatorios/estoque-geral/${'psicotropicos'}`);
        } else if (!switchCheck && params === null && !checked) {
            window.open(`/relatorios/estoque-geral/${'abertos'}`);
        } else {
            handleClose();
            if (switchCheck) {
                window.open(`/relatorios/estoque-geral/${'todos'}`);
            } else {
                window.open(`/relatorios/estoque-geral/${params}`);
            }
        }
    };

    const handleGetAllExames = async () => {
        const response = await api.getAllExames();
        if (response.status === 200) {
            setExamesAll(response.data);
        }
    };
    const handleChangeSwitchAll = async (dataInicio, dataFinal) => {
        if (!dataInicio || !dataFinal) {
            toast.error('As Datas Sao Obrigatorias');
            return;
        }

        const response = await api.listaPacientePorExamesTodos(dataInicio, dataFinal);

        if (response.status === 200) {
            setOpenPdf(true);
        }
        setDataPdf(response.data);
    };

    const handleGetPacientesPorExames = async (dataInicio, dataFinal, exame_id) => {
        const exameSelecionado = examesAll?.find((e) => e.id === exame_id?.item_id);

        const response = await api.listaPacientePorExames(
            dataInicio,
            dataFinal,
            exameSelecionado?.especial ? null : exameSelecionado.id,
            exameSelecionado?.especial ? exameSelecionado.id : null
        );

        if (response.status === 200) {
            setOpenPdf(true);
        }
        setDataPdf(response.data);
    };

    const formatarData = (date) => {
        const dateObj = new Date(date);
        const year = dateObj.getUTCFullYear();
        const month = ('0' + (dateObj.getUTCMonth() + 1)).slice(-2);
        const day = ('0' + dateObj.getUTCDate()).slice(-2);
        return `${day}/${month}/${year}`;
    };
    useEffect(() => {
        getProducts();
        handleGetAllExames();
    }, []);

    return (
        <Box>
            <Dialog open={open} fullWidth={true} maxWidth={'md'}>
                <DialogContent>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ textAlign: 'center', padding: '.5em' }}>
                            <Typography variant="h3">RELATÓRIO DE LISTA DE PACIENTES POR EXAME E PERÍODO</Typography>
                        </Box>
                        <Box>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={openPdf}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button'
                                }}
                                sx={{ display: 'flex', left: '60%', top: '-34%' }}
                            >
                                <Button
                                    onClick={() => {
                                        setOpen(false), setOpenPdf(false);
                                    }}
                                >
                                    <PDFDownloadLink
                                        document={
                                            <PdfPorPacientePorExames
                                                data={{ dataDate, dataPdf: dataPdf, filtro: switchCheckAll ? 'todos' : itemSelecionado }}
                                            />
                                        }
                                        fileName={`relatório-${formatarData(new Date())}`}
                                    >
                                        {({ blob, url, loading, error }) => (loading ? 'Carregando' : 'Gerar PDF')}
                                    </PDFDownloadLink>
                                </Button>
                            </Menu>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ padding: '.4em' }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disabled={switchCheckAll}
                                        options={examesAll}
                                        getOptionLabel={(examesAll) => `${examesAll?.id} - ${examesAll?.nome}`}
                                        value={examesAll?.find((el) => el.id === itemSelecionado?.item_id || null)}
                                        onChange={(e, newValue) => handleItemSelect(newValue)}
                                        renderInput={(params) => <TextField {...params} label="Item" />}
                                    />
                                </FormControl>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '.4em',
                                        gap: '2em',
                                        marginTop: '20px'
                                    }}
                                >
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Item"
                                            type="date"
                                            value={product}
                                            disabled={switchCheck}
                                            onChange={(e) => handleChangeInputDate('firstDate', e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </FormControl>
                                    <Typography>Até</Typography>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Item"
                                            type="date"
                                            value={product}
                                            disabled={switchCheck}
                                            onChange={(e) => handleChangeInputDate('lastDate', e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </FormControl>
                                </Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={switchCheckAll}
                                            onChange={() => setSwitchCheckAll(!switchCheckAll)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Todos"
                                />
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (switchCheckAll) {
                                handleChangeSwitchAll(dataDate?.firstDate, dataDate?.lastDate);
                                return;
                            }
                            handleGetPacientesPorExames(dataDate?.firstDate, dataDate?.lastDate, itemSelecionado);
                        }}
                    >
                        Gerar
                    </Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setOpen(true)}>Abrir</Button>
        </Box>
    );
};

export default ActionButtonGeral;
