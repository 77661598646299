import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useApi } from 'Service/axios';
import { HeaderBox, HeaderInfo, InfoBox } from './style';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import DataArrayIcon from '@mui/icons-material/DataArray';
import { formatarSUS } from 'utils/formatSUS';
import { formatarRG } from 'utils/formatRG';
import { formatarCPF } from 'utils/formatCpf';
import formater from 'utils/DateDDMMYYY';
import { toast } from 'react-toastify';

const EntradaNovaVersao = () => {
    const { id, numero } = useParams();
    const api = useApi();
    const [paciente, setPaciente] = useState(null);
    const [dadosProntuario, setDadosProntuario] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');
    const [idade, setIdade] = useState('');

    const getPaciente = async (id) => {
        const response = await api.getPacienteById(id);
        setPaciente(response.data);

        setTimeout(() => {
            setLoading(false);
        }, 1000);

        // ======================== DATA + IDADE ========================

        const formatDate = (inputDate) => {
            const dateParts = inputDate.split('-');
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]) - 1;
            const day = parseInt(dateParts[2]);
            return new Date(year, month, day);
        };

        const formatDateNascimento = (inputDate) => {
            const dateParts = inputDate.split('-');
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]);
            const day = parseInt(dateParts[2]);
            return day + '/' + month + '/' + year;
        };

        const dataNascimento = formatDate(response?.data?.data_nascimento);

        const dataAtual = new Date();

        const diffMilliseconds = dataAtual - dataNascimento;
        const diffDate = new Date(diffMilliseconds);

        const idadeAnos = diffDate.getUTCFullYear() - 1970;
        const idadeMeses = diffDate.getUTCMonth();
        const idadeDias = diffDate.getUTCDate() - 1;

        setIdade(`${idadeAnos} anos, ${idadeMeses} meses e ${idadeDias} dias`);
        setData(formater(response.data.data_nascimento));
    };

    async function getProntuario(prontuarioId) {
        try {
            const { data } = await api.getProntuarioById(prontuarioId);
            setDadosProntuario(data);
        } catch (error) {
            toast.error('Erro ao buscar dados do prontuário');
        }
    }

    const formatarData = (dataISO) => {
        if (!dataISO) return '';
        const data = new Date(dataISO);
        return new Intl.DateTimeFormat('pt-BR', {
            timeZone: 'America/Sao_Paulo',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).format(data);
    };

    if (loading === false && paciente.nome_completo) {
        window.print();
    }

    useEffect(() => {
        getPaciente(id);
        getProntuario(numero);
    }, [id, numero]);

    return (
        <>
            {paciente && (
                <Box sx={{ width: '100%', marginLeft: '1.2em', padding: '30px' }}>
                    <HeaderBox>
                        <HeaderInfo>
                            F.A: <strong>{numero}</strong>
                        </HeaderInfo>
                        <HeaderInfo>
                            PRONTUÁRIO: <strong>{id}</strong>
                        </HeaderInfo>
                        <HeaderInfo>CONVENIO: {paciente?.convenio?.descricao ?? ''}</HeaderInfo>
                        <HeaderInfo>
                            DATA/HORA F.A: <strong>{formatarData(dadosProntuario?.createdAt) ?? ''}</strong>
                        </HeaderInfo>
                    </HeaderBox>
                    <Box sx={{ borderBottom: '1px solid #000' }}>
                        <InfoBox>
                            <HeaderInfo>PACIENTE: {paciente.nome_completo}</HeaderInfo>
                            <HeaderInfo>NOME SOCIAL: {paciente.nome_social}</HeaderInfo>
                            <HeaderInfo>NASC: {data}</HeaderInfo>
                            <HeaderInfo>SEXO: {paciente.sexo == 1 ? 'M' : paciente.sexo == 2 ? 'F' : 'Outros'}</HeaderInfo>
                        </InfoBox>
                        <InfoBox>
                            <HeaderInfo>COR: {paciente.cor}</HeaderInfo>
                            <HeaderInfo>IDADE: {idade}</HeaderInfo>
                            <HeaderInfo>NATURALIDADE: {paciente.naturalidade}</HeaderInfo>
                            <HeaderInfo>ESTADO CIVIL: {paciente.estado_civil}</HeaderInfo>
                        </InfoBox>
                        <InfoBox>
                            <HeaderInfo>MÃE: {paciente.nome_mae}</HeaderInfo>
                            <HeaderInfo>CPF: {paciente.cpf ? formatarCPF(paciente.cpf) : ''}</HeaderInfo>
                            <HeaderInfo>RG: {paciente.rg ? formatarRG(paciente.rg) : ''}</HeaderInfo>
                            <HeaderInfo>SUS: {paciente.cartao_sus ? formatarSUS(paciente.cartao_sus) : ''}</HeaderInfo>
                        </InfoBox>
                        <InfoBox>
                            <HeaderInfo>
                                ENDEREÇO: {paciente.endereco}, {paciente.numero} - {paciente.bairro} - {paciente.cidade} - {paciente.estado}
                            </HeaderInfo>
                        </InfoBox>
                        <InfoBox>
                            <HeaderInfo>CEP: {paciente.cep}</HeaderInfo>
                            <HeaderInfo>TELEFONE: {paciente.telefone}</HeaderInfo>
                            <HeaderInfo>AUTISMO: {paciente.autismo ? 'SIM' : 'NÃO'}</HeaderInfo>
                        </InfoBox>
                    </Box>
                    <Box sx={{ padding: '.5em 0 .5em 0', borderBottom: '1px solid #000' }}>
                        <Box>
                            <HeaderInfo>1 - CLASSIFICAÇÃO DE RISCO</HeaderInfo>
                            <Box sx={{ display: 'flex' }}>
                                <HeaderInfo>QUEIXA E DURAÇÃO:</HeaderInfo>
                                <HeaderInfo>
                                    _________________________________________________________________________________________
                                </HeaderInfo>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '.3em 0 .3em 0' }}>
                                <HeaderInfo sx={{ display: 'flex', alignItems: 'center' }}>
                                    DEAMBULA: <DataArrayIcon sx={{ fontSize: '1.3em' }} /> SIM <DataArrayIcon sx={{ fontSize: '1.3em' }} />
                                    NÃO
                                </HeaderInfo>
                                <HeaderInfo sx={{ display: 'flex', alignItems: 'center' }}>
                                    CONSIENTE: <DataArrayIcon sx={{ fontSize: '1.3em' }} /> SIM <DataArrayIcon sx={{ fontSize: '1.3em' }} />
                                    NÃO
                                </HeaderInfo>
                                <HeaderInfo sx={{ display: 'flex', alignItems: 'center' }}>
                                    SANGRA:
                                    <DataArrayIcon sx={{ fontSize: '1.3em' }} /> SIM <DataArrayIcon sx={{ fontSize: '1.3em' }} /> NÃO
                                </HeaderInfo>
                                <HeaderInfo sx={{ display: 'flex', alignItems: 'center' }}>
                                    DESC. RESP: <DataArrayIcon sx={{ fontSize: '1.3em' }} /> SIM
                                    <DataArrayIcon sx={{ fontSize: '1.3em' }} /> NÃO
                                </HeaderInfo>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            borderBottom: '1px solid #000',
                            display: 'flex',
                            padding: '.3em 0 .3em 0',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ width: '24%', border: '1px solid #red', marginRight: 'auto' }}>
                                <Typography sx={{ fontSize: '.8em' }}>2 - SINAIS VITAIS</Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography>PA:___________</Typography>
                                    <Typography>X:_________</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography>FC:__________</Typography>
                                    <Typography>SAT:_______</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography>TEMP:__________________</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography>DEXTRO:________________</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ width: '24%', border: '1px solid #blue', marginRight: 'auto' }}>
                                <Typography>Dor-grau:_______________</Typography>
                                <Typography>Medicamentos:</Typography>
                                <Typography>____________________</Typography>
                                <Typography>____________________</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ width: '24%', border: '1px solid #red', marginRight: 'auto' }}>
                                <Typography sx={{ fontSize: '.8em' }}>3 - DADOS COMPLEMENTARES</Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '1em' }}>
                                        <DataArrayIcon sx={{ fontSize: '1em' }} /> HAS
                                    </Typography>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', marginLeft: '3.2em', fontSize: '1em' }}>
                                        <DataArrayIcon sx={{ fontSize: '1em' }} /> DPOC
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '1em' }}>
                                        <DataArrayIcon sx={{ fontSize: '1em' }} /> DM
                                    </Typography>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', marginLeft: '3.2em', fontSize: '1em' }}>
                                        <DataArrayIcon sx={{ fontSize: '1em' }} /> LITÍASE
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '1em' }}>
                                        <DataArrayIcon sx={{ fontSize: '1em' }} /> OUTROS
                                    </Typography>
                                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>____________</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '1em' }}>
                                        <DataArrayIcon sx={{ fontSize: '1em' }} /> ALERGIA
                                    </Typography>
                                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>____________</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ width: '24%', border: '1px solid #blue' }}>
                                <Box sx={{ textAlign: 'center', marginTop: '1em' }}>
                                    <Typography sx={{ fontSize: '.85em', marginBottom: '.3em' }}>ENFERMAGEM COREN</Typography>
                                    <Typography sx={{ fontSize: '.85em' }}>_______________</Typography>
                                    <Typography sx={{ fontSize: '.85em', marginBottom: '.3em' }}>HORÁRIO</Typography>
                                    <Typography sx={{ fontSize: '.85em' }}>______:______</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ padding: '.3em 0 .3em 0', borderBottom: '1px solid #000', flexWrap: 'wrap' }}>
                        <Typography>5- PRINCIPAIS DADOS DE ANAMNESE EXAME FÍSICO</Typography>
                        <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography>
                        <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography>
                        <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography>
                        <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography>
                        <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography>
                        <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography>
                        {/* <Typography>
                            ________________________________________________________________________________________________________________
                        </Typography> */}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '.5em 0 .5em 0', borderBottom: '1px solid #000' }}>
                        <Typography>6- EXAMES COMPLEMENTARES E SUBSIDIÁRIOS</Typography>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ marginRight: '3em', display: 'flex', alignItems: 'center' }}>
                                <DataArrayIcon sx={{ fontSize: '1em' }} /> ECG
                            </Typography>
                            <Typography>IMAGEM:</Typography>
                            <Typography>
                                __________________________________________________________________________________________
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '.3em 0 .3em 0' }}>
                            <Typography>7- DIAGNÓSTICOS</Typography>
                            <Typography>A- PRINCIPAL CID: ________________________</Typography>
                            <Typography>B- SECUNDÁRIO CID: ________________________</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ padding: '.3em 0 .3em 0', borderBottom: '1px solid #000' }}>
                        <Typography sx={{ textAlign: 'center' }}>8- PRESCRIÇÃO MÉDICA E ANOTAÇÕES DE ENFERMAGEM</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1em' }}>
                            <Box>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                <Typography>_________________________________________________________</Typography>
                                {/* <Typography>_________________________________________________________</Typography> */}
                                {/* <Typography>_________________________________________________________</Typography> */}
                            </Box>
                            <Box>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                <Typography>____________________________________________________</Typography>
                                {/* <Typography>____________________________________________________</Typography> */}
                                {/* <Typography>____________________________________________________</Typography> */}
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{ display: 'flex', justifyContent: 'space-between', padding: '.3em 0 .3em 0', borderBottom: '1px solid #000' }}
                    >
                        <Typography>9- DESTINO</Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> ALTA
                        </Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> UBS
                        </Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> OBSERVAÇÃO
                        </Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> INTERNAÇÃO
                        </Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <DataArrayIcon sx={{ fontSize: '1em' }} /> ÓBITO
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '4.7em' }}>
                        <Box>
                            <Typography>__________________________________________________</Typography>
                            <Typography sx={{ textAlign: 'center' }}>10- ASSINATURA DO PACIENTE OU RESPONSÁVEL</Typography>
                        </Box>
                        <Box>
                            <Typography>__________________________________________________</Typography>
                            <Typography sx={{ textAlign: 'end' }}>11- ASSINATURA DO MÉDICO</Typography>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default EntradaNovaVersao;
